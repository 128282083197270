
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react"
import './Reveal.css'
import Button from './Button'
import Modal from './Modal'
import HelpIcon from './HelpIcon.jsx'
import { CardContext, CardDispatchContext } from "../context/CardsProvider.jsx";

function Reveal() {
    const location = useLocation();

    let { threeCards, celticCross, drawOne } = location.state ?? false;

    const summaryRef = useRef(null)

    let navigate = useNavigate();

    const {shuffledDeck, cardsSelected, selectedReversed} = useContext(CardContext);
    const {setShuffledDeck, setCardsSelected, setSelectedReversed} = useContext(CardDispatchContext);

    const [reveal, setReveal] = useState(false)
    const [image, setImage] = useState("")
    const [title, setTitle] = useState("")
    const [upwardMeaning, setUpwardMeaning] = useState("")
    const [downwardMeaning, setDownwardMeaning] = useState("")
    const [position, setPosition] = useState("")
    const [description, setDescription] = useState("")
    const [reversed, setReversed] = useState("")
    const [renderSelected, setRenderSelected] = useState([])
    const [revealHelp, setRevealHelp] = useState(false)
    const [revealSummary, setRevealSummary] = useState(false)
    const [cardsPositions, setCardsPositions] = useState([])
    const [cardsDescriptions, setCardsDescriptions] = useState([])

    const backToTop = () => {
        summaryRef.current?.scrollIntoView({ block:'start' });
    }
    
    const revealModal = (image, title, reversed, position, description, upward, downward) => {
        setImage(image)
        setTitle(title)
        setPosition(position)
        setReveal(true)
        setReversed(reversed)
        setDescription(description)
        setUpwardMeaning(upward)
        setDownwardMeaning(downward)
    }

    const hideModal = () => {
        setReveal(false)
    } 

    const toggleModalSummary = () => {
        setRevealSummary(prev => !prev)
    } 

    const toggleHelp = () => {
        setRevealHelp(prev => !prev)
    }

    useEffect(() => {
        if (threeCards) {
            setCardsPositions(["Present", "Past Influences", "Possible Outcome"])
            setCardsDescriptions([
                "Current situation the querent consults.", 
                "Situations of the past that influenced the present.", 
                "One of the possible scenarios that may happen."
            ])
        } else if (celticCross) {
            setCardsPositions([
                "Situation", 
                "Cross Purpose",
                "Foundation",
                "Past Influences",
                "Possible Future",
                "Past",
                "Self",
                "Environment",
                "Hopes and Fears",
                "Outcome"
            ])
            setCardsDescriptions([
                "Overall situation on why the querent consults the Tarot.", 
                "Current factors that play against the querent to fulfill his goal.", 
                "Past experiences that keep the querent from reaching his goals",
                "Influences of the past that made the querent take this path",
                "Near future that may occur",
                "The past of the querent",
                "How the querent feels about himself.",
                "External factors such as society, family and friends.",
                "Expresses how the querent hopes for the situation to be or ideas that hold the querent back.",
                "The result of all the circumstances."
            ])
        }
    }, [setCardsPositions, celticCross, threeCards])
    
    useEffect(() => {
        const shuffled = JSON.parse(localStorage.getItem('shuffledDeck'));
        const cards = JSON.parse(localStorage.getItem('cardsSelected'));
        const selected = JSON.parse(localStorage.getItem('selectedReversed'));
        if (shuffled && cards && selected && location.state) {
            setShuffledDeck(shuffled)
            setCardsSelected(cards)
            setSelectedReversed(selected)
        } else {
            navigate('/', { replace: true });
        }

    }, [ setCardsSelected, setSelectedReversed, setShuffledDeck, navigate, location.state ]);

    useEffect(() => {
        setRenderSelected(cardsSelected.map((card) => {
            return shuffledDeck[card]
        }));
    }, [cardsSelected , shuffledDeck])

    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement('script');
      
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7881143807123640";
        script.async = true;
        script.crossOrigin = "anonymous"
      
        head.appendChild(script);
      
        return () => {
          head.removeChild(script);
        }
      }, []);
      
    return (
        <section className="reveal">
            <div className="reveal__spreads">
                {drawOne && renderSelected.length > 0 && (
                    <section class="draw-one__container">
                        <div class="draw-one__content">
                            <h1 className="reveal__title">Draw One Card</h1>
                            <img 
                            src={renderSelected[0].image} 
                            alt={renderSelected[0].title} 
                            className={`reveal-image three-cards ${selectedReversed[0] ? 'reversed' : ''}`} 
                            onClick={() => revealModal(
                                renderSelected[0].image, 
                                renderSelected[0].title, 
                                selectedReversed[0], 
                                "Yes/No", 
                                "Answers a single question from the querent.", 
                                renderSelected[0].upward, 
                                renderSelected[0].downward
                            )}
                            />
                        </div>
                        <Link to={`/`} className="decoration-none reveal__return-home--draw-one" aria-label="Click here to Return Home"><Button>Return Home</Button></Link>
                    </section>
                )}
                {threeCards && renderSelected.length > 0 && (
                        <div className='three-cards__container'>
                            <h1 className="reveal__title">Three Cards Spread</h1>
                            <div className="three-cards__selected-cards">
                                <div className="three-cards__card-container">
                                <img 
                                    src={renderSelected[1].image} 
                                    alt={renderSelected[1].title} 
                                    className={`reveal-image three-cards ${selectedReversed[1] ? 'reversed' : ''}`} 
                                    onClick={() => revealModal(
                                        renderSelected[1].image, 
                                        renderSelected[1].title, 
                                        selectedReversed[1], 
                                        cardsPositions[1], 
                                        cardsDescriptions[1], 
                                        renderSelected[1].upward, 
                                        renderSelected[1].downward
                                    )}
                                />
                                </div>
                                <div className="three-cards__card-container three-cards__present">
                                    <img 
                                        src={renderSelected[0].image} 
                                        alt={renderSelected[0].title} 
                                        className={`reveal-image three-cards ${selectedReversed[0] ? 'reversed' : ''}`} 
                                        onClick={() => revealModal(
                                            renderSelected[0].image, 
                                            renderSelected[0].title, 
                                            selectedReversed[0], 
                                            cardsPositions[0], 
                                            cardsDescriptions[0], 
                                            renderSelected[0].upward, 
                                            renderSelected[0].downward
                                        )}
                                    />
                                </div>
                                <div className="three-cards__card-container">
                                    <img 
                                        src={renderSelected[2].image} 
                                        alt={renderSelected[2].title} 
                                        className={`reveal-image three-cards ${selectedReversed[2] ? 'reversed' : ''}`} 
                                        onClick={() => revealModal(
                                            renderSelected[2].image, 
                                            renderSelected[2].title, 
                                            selectedReversed[2], 
                                            cardsPositions[2], 
                                            cardsDescriptions[2], 
                                            renderSelected[2].upward, 
                                            renderSelected[2].downward
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="reveal__btn-container">
                                <div onClick={toggleModalSummary}>
                                    <Button>See Summary</Button>
                                </div>
                                <Link to={`/`} className="decoration-none reveal__return-home--three-cards" aria-label="Click here to Return Home"><Button>Return Home</Button></Link>
                            </div>
                        </div>
                    )}
                    
                {celticCross && renderSelected.length > 0 &&
                <div className="celtic-cross">
                    <h1 className="celtic-cross__title">Celtic Cross</h1>
                    <div className="celtic-cross__container">
                        <img 
                            src={renderSelected[0].image} 
                            alt={renderSelected[0].title} 
                            className={`reveal-image celtic-cross__card situation ${selectedReversed[0] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[0].image, 
                                renderSelected[0].title, 
                                selectedReversed[0], 
                                cardsPositions[0], 
                                cardsDescriptions[0], 
                                renderSelected[0].upward, 
                                renderSelected[0].downward
                            )}
                        />
                        <img 
                            src={renderSelected[1].image} 
                            alt={renderSelected[1].title} 
                            className={`reveal-image celtic-cross__card cross-purpose ${selectedReversed[1] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[1].image, 
                                renderSelected[1].title, 
                                selectedReversed[1], 
                                cardsPositions[1], 
                                cardsDescriptions[1],
                                renderSelected[1].upward, 
                                renderSelected[1].downward
                            )}
                        />
                        <img 
                            src={renderSelected[2].image} 
                            alt={renderSelected[2].title} 
                            className={`reveal-image celtic-cross__card foundation ${selectedReversed[2] ? 'reversed' : ''}`} 
                            onClick={() => revealModal(
                                renderSelected[2].image, 
                                renderSelected[2].title, 
                                selectedReversed[2], 
                                cardsPositions[2], 
                                cardsDescriptions[2],
                                renderSelected[2].upward, 
                                renderSelected[2].downward
                            )}
                        />
                        <img 
                            src={renderSelected[3].image} 
                            alt={renderSelected[3].title} 
                            className={`reveal-image celtic-cross__card past-influences ${selectedReversed[3] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[3].image, 
                                renderSelected[3].title, 
                                selectedReversed[3], 
                                cardsPositions[3],
                                cardsDescriptions[3],
                                renderSelected[3].upward, 
                                renderSelected[3].downward
                            )}
                        />
                        <img 
                            src={renderSelected[4].image} 
                            alt={renderSelected[4].title} 
                            className={`reveal-image celtic-cross__card future ${selectedReversed[4] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[4].image, 
                                renderSelected[4].title, 
                                selectedReversed[4], 
                                cardsPositions[4],
                                cardsDescriptions[4],
                                renderSelected[4].upward, 
                                renderSelected[4].downward
                            )}
                        />
                        <img 
                            src={renderSelected[5].image} 
                            alt={renderSelected[5].title} 
                            className={`reveal-image celtic-cross__card past ${selectedReversed[5] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[5].image, 
                                renderSelected[5].title, 
                                selectedReversed[5], 
                                cardsPositions[5],
                                cardsDescriptions[5],
                                renderSelected[5].upward, 
                                renderSelected[5].downward
                            )}
                        />
                        <img 
                            src={renderSelected[6].image} 
                            alt={renderSelected[6].title} 
                            className={`reveal-image celtic-cross__card self ${selectedReversed[6] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[6].image, 
                                renderSelected[6].title, 
                                selectedReversed[6], 
                                cardsPositions[6], 
                                cardsDescriptions[6],
                                renderSelected[6].upward, 
                                renderSelected[6].downward
                            )}
                        />
                        <img 
                            src={renderSelected[7].image} 
                            alt={renderSelected[7].title} 
                            className={`reveal-image celtic-cross__card environment ${selectedReversed[7] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[7].image, 
                                renderSelected[7].title, 
                                selectedReversed[7], 
                                cardsPositions[7], 
                                cardsDescriptions[7],
                                renderSelected[7].upward, 
                                renderSelected[7].downward
                            )}
                        />
                        <img 
                            src={renderSelected[8].image} 
                            alt={renderSelected[8].title} 
                            className={`reveal-image celtic-cross__card hopes-fears ${selectedReversed[8] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[8].image, 
                                renderSelected[8].title, 
                                selectedReversed[8], 
                                cardsPositions[8], 
                                cardsDescriptions[8],
                                renderSelected[8].upward, 
                                renderSelected[8].downward
                            )}
                        />
                        <img 
                            src={renderSelected[9].image} 
                            alt={renderSelected[9].title} 
                            className={`reveal-image celtic-cross__card outcome ${selectedReversed[9] ? 'reversed' : ''}`}
                            onClick={() => revealModal(
                                renderSelected[9].image, 
                                renderSelected[9].title, 
                                selectedReversed[9], 
                                cardsPositions[9], 
                                cardsDescriptions[9],
                                renderSelected[9].upward, 
                                renderSelected[9].downward
                            )}
                        />
                    </div>
                    <div className="reveal__btn-container--celtic-cross">
                        <div onClick={toggleModalSummary}>
                            <Button>See Summary</Button>
                        </div>
                        <Link to={`/`} aria-label="Click here to Return Home" className="decoration-none"><Button>Return Home</Button></Link>
                    </div>
                </div>}
            </div>

            <div className="reveal__help-icon" onClick={toggleHelp}>
                <HelpIcon />
            </div>

            <Modal reveal={reveal}>
                <div className="reveal_modal-container">
                    <p onClick={hideModal} className="modal-exit">X</p>
                    <a href={`${image}`} aria-label={title} target="_blank" rel="noreferrer">
                        <img src={image} alt={title} className={`reveal-image ${reversed ? 'reversed' : ''}`} />
                    </a>
                    <h2 className="reveal__modal-title">{title}</h2>
                    <h3>{position}</h3>
                    {description && (
                        <p className="reveal__modal-description">{description}</p>
                    )}
                    <p>Card Meaning: {reversed ? downwardMeaning : upwardMeaning}</p>
                </div>
            </Modal>

            <Modal reveal={revealSummary}>
                <div className="reveal_modal-container" ref={summaryRef}>
                    <p onClick={toggleModalSummary} className="summary__modal-exit">X</p>
                    {cardsSelected.map((selected, index) => {
                        return (
                            <div className="reveal__summary-container" key={index}>
                                <a href={`${shuffledDeck[selected].image}`} aria-label={shuffledDeck[selected].title} target="_blank" rel="noreferrer">
                                    <img src={shuffledDeck[selected].image} alt={shuffledDeck[selected].title} className={`reveal-image ${selectedReversed[index] ? 'reversed' : ''}`} />
                                </a>
                                <h2 className="reveal__modal-title">{shuffledDeck[selected].title}</h2>
                                <h3>{cardsPositions[index]}</h3>
                                {cardsDescriptions[index] && (
                                    <p className="reveal__modal-description">{cardsDescriptions[index]}</p>
                                )}
                                <p className="reveal__modal-description">Card Meaning: {selectedReversed[index] ? shuffledDeck[selected].downward : shuffledDeck[selected].upward}</p>
                                <span className="meanings__back-top" onClick={backToTop}>Back to top</span>
                            </div>
                        )
                    })}
                </div>
            </Modal>

            <Modal reveal={revealHelp}>
                <div className="reveal_modal-container">
                    <p onClick={toggleHelp} className="modal-exit">X</p>
                    <p>You can click on the images or tap them to see the meaning.</p>
                </div>
            </Modal>
        </section>
        
    );
}

export default Reveal;
