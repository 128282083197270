import Fool from '../assets/images/Fool.jpg'
import Magician from '../assets/images/Magician.jpg'
import HighPriestess from '../assets/images/High_Priestess.jpg'
import TheEmpress from '../assets/images/Empress.jpg'
import TheEmperor from '../assets/images/Emperor.jpg'
import Hierophant from '../assets/images/Hierophant.jpg'
import TheLovers from '../assets/images/TheLovers.jpg'
import Chariot from '../assets/images/Chariot.jpg'
import Strength from '../assets/images/Strength.jpg'
import Hermit from '../assets/images/Hermit.jpg'
import WheelOfFortune from '../assets/images/Wheel_of_Fortune.jpg'
import Justice from '../assets/images/Justice.jpg'
import HangedMan from '../assets/images/Hanged_Man.jpg'
import Death from '../assets/images/Death.jpg'
import Temperance from '../assets/images/Temperance.jpg'
import Devil from '../assets/images/Devil.jpg'
import Tower from '../assets/images/Tower.jpg'
import Star from '../assets/images/Star.jpg'
import Moon from '../assets/images/Moon.jpg'
import Sun from '../assets/images/Sun.jpg'
import Judgement from '../assets/images/Judgement.jpg'
import World from '../assets/images/World.jpg'

import Wands01 from '../assets/images/Wands01.jpg'
import Wands02 from '../assets/images/Wands02.jpg'
import Wands03 from '../assets/images/Wands03.jpg'
import Wands04 from '../assets/images/Wands04.jpg'
import Wands05 from '../assets/images/Wands05.jpg'
import Wands06 from '../assets/images/Wands06.jpg'
import Wands07 from '../assets/images/Wands07.jpg'
import Wands08 from '../assets/images/Wands08.jpg'
import Wands09 from '../assets/images/Wands09.jpg'
import Wands10 from '../assets/images/Wands10.jpg'
import Wands11 from '../assets/images/Wands11.jpg'
import Wands12 from '../assets/images/Wands12.jpg'
import Wands13 from '../assets/images/Wands13.jpg'
import Wands14 from '../assets/images/Wands14.jpg'

import Cups01 from '../assets/images/Cups01.jpg'
import Cups02 from '../assets/images/Cups02.jpg'
import Cups03 from '../assets/images/Cups03.jpg'
import Cups04 from '../assets/images/Cups04.jpg'
import Cups05 from '../assets/images/Cups05.jpg'
import Cups06 from '../assets/images/Cups06.jpg'
import Cups07 from '../assets/images/Cups07.jpg'
import Cups08 from '../assets/images/Cups08.jpg'
import Cups09 from '../assets/images/Cups09.jpg'
import Cups10 from '../assets/images/Cups10.jpg'
import Cups11 from '../assets/images/Cups11.jpg'
import Cups12 from '../assets/images/Cups12.jpg'
import Cups13 from '../assets/images/Cups13.jpg'
import Cups14 from '../assets/images/Cups14.jpg'

import Swords01 from '../assets/images/Swords01.jpg'
import Swords02 from '../assets/images/Swords02.jpg'
import Swords03 from '../assets/images/Swords03.jpg'
import Swords04 from '../assets/images/Swords04.jpg'
import Swords05 from '../assets/images/Swords05.jpg'
import Swords06 from '../assets/images/Swords06.jpg'
import Swords07 from '../assets/images/Swords07.jpg'
import Swords08 from '../assets/images/Swords08.jpg'
import Swords09 from '../assets/images/Swords09.jpg'
import Swords10 from '../assets/images/Swords10.jpg'
import Swords11 from '../assets/images/Swords11.jpg'
import Swords12 from '../assets/images/Swords12.jpg'
import Swords13 from '../assets/images/Swords13.jpg'
import Swords14 from '../assets/images/Swords14.jpg'

import Pents01 from '../assets/images/Pents01.jpg'
import Pents02 from '../assets/images/Pents02.jpg'
import Pents03 from '../assets/images/Pents03.jpg'
import Pents04 from '../assets/images/Pents04.jpg'
import Pents05 from '../assets/images/Pents05.jpg'
import Pents06 from '../assets/images/Pents06.jpg'
import Pents07 from '../assets/images/Pents07.jpg'
import Pents08 from '../assets/images/Pents08.jpg'
import Pents09 from '../assets/images/Pents09.jpg'
import Pents10 from '../assets/images/Pents10.jpg'
import Pents11 from '../assets/images/Pents11.jpg'
import Pents12 from '../assets/images/Pents12.jpg'
import Pents13 from '../assets/images/Pents13.jpg'
import Pents14 from '../assets/images/Pents14.jpg'

const deck = [
    {
        title: "The Fool",
        image: Fool,
        upward: "The Fool represents new beginnings, positivism, being naive, and a free spirit. It's a reminder to break the routine from day to day.",
        downward: "Downward, the Fool can represent new beginnings but avoid being reckless and careless as there may be difficulties on the path."
    },
    {
        title: "The Magician",
        image: Magician,
        upward: "The Magician represents the master of manifestation. You are ready to make your dreams come true as you are now equipped with everything in your power to manifest your desires.",
        downward: "Downward, the Magician has all in his power to destroy achievements, but not in an evil manner but may just bring positive change."
    },
    {
        title: "High Priestess",
        image: HighPriestess,
        upward: "The High Priestess asks the querent to meditate and this way accesses inner wisdom. Take some time to access this inner knowledge.",
        downward: "Downward, The High Priestess tells you that you are not aligned with your inner wisdom and not listening. Meditation is encouraged to access this inner knowledge."
    },
    {
        title: "The Empress",
        image: TheEmpress,
        upward: "The Empress represents motherhood and everything that is encompassed, such as joys, pleasures, and abundance. It represents the energy of creation.",
        downward: "Downward, the Empress encourages the querent to practice self-love in every aspect and recognizes every effort made to strive."
    },
    {
        title: "The Emperor",
        image: TheEmperor,
        upward: "The Emperor represents a father figure who is protective, authoritative, and demands respect from others.",
        downward: "Downward, the Emperor asks to assess if the querent is a leader or a boss and the approach to leadership."
    },
    {
        title: "The Hierophant",
        image: Hierophant,
        upward: "The Hierophant represents the norm, the traditional, and what is established. It conforms to the status quo and follows it. Generally related to institutions such as the orthodox church.",
        downward: "Downward, the Hierophant reminds the querent about free and bohemian lifestyles that don't follow the norm or what is expected and, in some cases, are very independent of the standards of society."
    },
    {
        title: "The Lovers",
        image: TheLovers,
        upward: "The Lovers is about the right choice, about love, and doing what is ethically correct. Finally not falling into temptation.",
        downward: "Downward, The Lovers indicates that the querent should be very careful about his choices and not fall into temptation as it may be regretted."
    },
    {
        title: "The Chariot",
        image: Chariot,
        upward: "The Chariot tells the querent that the right path to victory is ahead. With firm and bold decision-making, success will come true.",
        downward: "Downward, The Chariot tells the querent that victory is not set to happen and that another approach should be taken to the current situation."
    },
    {
        title: "Strength",
        image: Strength,
        upward: "Strength reminds the querent that force is not required to do one's will, compassion and patience may just be stronger and more effective.",
        downward: "Downward, Strength reminds the querent to not use force and use compassion, patience, and fortitude instead since it will be more effective. "
    },
    {
        title: "The Hermit",
        image: Hermit,
        upward: "The Hermit is a guide who has taken the path of solitude and has reached enlightenment through it. Consider his advice accurate as he knows from within and is very spiritual.",
        downward: "Downward, The Hermit indicates that there is a lack of progress spiritually either by isolating too much or not spending enough time in meditation."
    },
    {
        title: "The Wheel of Fortune",
        image: WheelOfFortune,
        upward: "The Wheel of Fortune is a positive omen that the querent is in flow with the laws of the universe. It reminds us that change is the only constant.",
        downward: "Downward, The Wheel of Fortune indicates that even if things are going well, the current direction of the querent is not aligned with the highest purpose."
    },
    {
        title: "Justice",
        image: Justice,
        upward: "Justice will bring into account the querent's actions and it will be judged fairly without bias. This card generally involves legal matters or external factors to the querent.",
        downward: "Downward, Justice wants to bring to the querent's attention that the final decision on the subject in the matter will feel unfair."
    },
    {
        title: "The Hanged Man",
        image: HangedMan,
        upward: "The Hanged man reminds the querent that even though others do not agree, it's vital to believe firmly in one's self.",
        downward: "Downward, The Hanged Man reminds the querent that even if more believe something, it doesn't mean most are following the highest good."
    },
    {
        title: "Death",
        image: Death,
        upward: "Death is not physical but more related to phases in life where you experience transformations and enter into new beginnings.",
        downward: "Downward, Death means that there is a blockage on the querent and it is not letting go of what was, even though a new phase is arriving."
    },
    {
        title: "Temperance",
        image: Temperance,
        upward: "Temperance asks the querent to embrace balance and look for a way to access different levels of consciousness.",
        downward: "Downward, Temperance is a clear sign that there is an imbalance in the querent's situation and careful measures should be taken to address the issue.",
    },
    {
        title: "The Devil",
        image: Devil,
        upward: "The Devil is an omen that the querent is being affected by negativity in any form, which could be temptations, your dark side, and anything that keeps you from being the best version.",
        downward: "Downward, The Devil is an omen that a negative cycle has been released and the querent has come to the realization of a new paradigm."
    },
    {
        title: "The Tower",
        image: Tower,
        upward: "The Tower is an omen that the unexpected will happen and there will be upheaval and confusion but it is because the foundations are not solid.",
        downward: "Downward, The Tower is an omen that the querent will continue on the same path as before even after there has been upheaval and destruction."
    },
    {
        title: "The Star",
        image: Star,
        upward: "The Star is reminding the querent about hope and optimism, that things will turn out right even if the situation has been difficult to navigate.",
        downward: "Downward, The Star means that the querent can be more optimistic and that hope has been lost. If things are tough right now, be sure there are reasons for which to be optimistic"
    },
    {
        title: "The Moon",
        image: Moon,
        upward: "The Moon represents mystery and unknown rules of the universe. These forces are affecting how the events unfold. Intuition and careful decision-making will be the best path forward.",
        downward: "Downward, The Moon represents misunderstandings and conflict for the querent due to unknown energies. Try to be extremely cautious about the decisions taken and follow your intuition."
    },
    {
        title: "The Sun",
        image: Sun,
        upward: "The Sun is a confirmation that the querent will attain success, glory, and delight in his journey. Happiness and strength lay the foundation to hold any struggle the universe may throw at your way.",
        downward: "Downward, The Sun is an indication that there are setbacks that may have affected the querent emotionally, and taking care of the things that you value the most is very important."
    },
    {
        title: "Judgement",
        image: Judgement,
        upward: "Judgement is a confirmation to the querent that a higher good is set for the person to accomplish following your intuition.",
        downward: "Downward, Judgement indicates that the querent has failed to follow the highest good and should reconsider carefully the next steps."
    },
    {
        title: "The World",
        image: World,
        upward: "The World is an indication to the querent that an important phase in your life is concluding successfully and has reached oneness with the highest good.",
        downward: "Downward, The World lets the querent know that success is foreseen yet you may be left unfulfilled spiritually due to incomplete projects or lack of progress."
    },
    {
        title: "Ace of Wands",
        image: Wands01,
        upward: "The Ace of Wands confirms the coming of a new creative project and it is your call to action to act upon it since with effort it could end as a success.",
        downward: "Downward, the Ace of Wands indicates delays in the project. Some blockages that are affecting your motivation leaving you feeling stagnated."
    },
    {
        title: "Two of Wands",
        image: Wands02,
        upward: "The Two of Wands brings to the querent's attention the desire to explore new horizons and venture into new ways.",
        downward: "Downward, the Two of Wands suggests that the querent is not seeing the progress he expects. Yet take time to explore your options and make a fitting plan."
    },
    {
        title: "Three of Wands",
        image: Wands03,
        upward: "The Three of Wands is mostly related to business projects that are in the beginning stage but have a great chance of success. It requires careful planning and foresight to overcome the challenges ahead.",
        downward: "Downward, the Three of Wands informs the querent that the project will experience delays due to lack of planning and foresight or due to unexpected events."
    },
    {
        title: "Four of Wands",
        image: Wands04,
        upward: "Four of Wands shows stability and it means a milestone has been reached and the querent may celebrate this achievement.",
        downward: "Downwards, the Four of Wands indicates that the querent is prosperous and happy but fails to recognize it."
    },
    {
        title: "Five of Wands",
        image: Wands05,
        upward: "The Five of Wand appears when there are times of fighting and a lot of competition in a group. This may be channeled by listening to the opinion each has to offer and come to a middle ground.",
        downward: "Downward, the Five of Wands indicates that times of fighting and competition have passed, and there is reconciliation."
    },
    {
        title: "Six of Wands",
        image: Wands06,
        upward: "The Six of Wands indicates that victory has come but this is not the end, there is still a long way of challenges to surpass.",
        downward: "Downward, the Six of Wands indicates success as well but at the cost of some delays and unexpected events that may add difficulties to the progress."
    },
    {
        title: "Seven of Wands",
        image: Wands07,
        upward: "Seven of Wands is an indication to hold your ground against all odds. That you have a position that many desire and others are willing to take it.",
        downward: "Downward, the Seven of Wands indicates that that querent should continue on the pursuit of his desires and should seek success and decisiveness."
    },
    {
        title: "Eight of Wands",
        image: Wands08,
        upward: "The Eight of Wands appears when the moment is now, it is now the time to take action and move forward with the plan or project in question.",
        downward: "Downward, the Eight of Wands appears when the querent should slow down and review the options carefully as there might be some details missing to continue. "
    },
    {
        title: "Nine of Wands",
        image: Wands09,
        upward: "The Nine of Wands represents the querent who has had a lot of fights and is wounded but is not willing to give anything up. But do you want this fight to be endless?.",
        downward: "Downward, the Nine of Wands indicates that the querent has fought until exhaustion but is no longer able to protect what was considered his own."
    },
    {
        title: "Ten of Wands",
        image: Wands10,
        upward: "The Ten of Wands reminds the querent of times when the responsibilities and the burdens were so many that the path in front is not even visible, yet it doesn't mean that the tasks won't be finished.",
        downward: "Downward, the Ten of Wands reminds the querent that the responsibilities and burdens are starting to take a toll and the situation should be re-assessed to avoid exhaustion."
    },
    {
        title: "Page of Wands",
        image: Wands11,
        upward: "The Page of Wands is full of creative, enthusiastic, and passionate energy which is around the querent. It indicates a strong driving force to create and learn about everything.",
        downward: "Downward, the Page of Wands indicates that there are blockages in creativity and enthusiasm. The querent should consider the possible causes for the lack of progress by exploring different areas."
    },
    {
        title: "Knight of Wands",
        image: Wands12,
        upward: "The Knight of Wands is a courageous, energetic, competitive person who at times may be wrong by his impulsivity.",
        downward: "Downward, the Knight of Wands indicates that there is a lack of security and that the person should take the time to restore his confidence."
    },
    {
        title: "Queen of Wands",
        image: Wands13,
        upward: "The Queen of Wands is an attractive, spontaneous, versatile, energetic, and grounded woman ready for the pursuit of creativity.",
        downward: "Downward, the Queen of Wands may indicate the querent is lacking confidence and should strive to build it up to continue with the objectives at hand."
    },
    {
        title: "King of Wands",
        image: Wands14,
        upward: "The King of Wands is a very secure, natural born leader in the field of creativity with a fiery will, passionate, good-hearted but impulsive at times.",
        downward: "Downward, the King of Wands is a person who is in the position of a leader in the creative field but he is being unjust and aggressive."
    },
    {
        title: "Ace of Cups",
        image: Cups01,
        upward: "The Ace of Cups is the beginning of prosperity, health, love, joy for the querent. There is a creative force in this card and the appearance of this card is an invitation to create.",
        downward: "Downward, the Ace of Cup is reminding the querent that for success it is necessary to put your heart into the right place. Reconsider the reasons why there is a blockage in creativity."
    },
    {
        title: "Two of Cups",
        image: Cups02,
        upward: "The Two of Cups is an indication of emotional compatibility and strong bonds. It could represent a job, relationship, or a friendship.",
        downward: "Downward, the Two of Cups asks the querent to work any misunderstandings as they may just be temporary but could affect your bonds either at work, socially, or in love."
    },
    {
        title: "Three of Cups",
        image: Cups03,
        upward: "The Three of Cups is a sign of sisterhood, celebration, and harmony. There may be a lot of social events around you and that is great!",
        downward: "Downward, the Three of Cups reminds us to avoid overindulgence and excesses and that sometimes a bit of time alone is beneficial."
    },
    {
        title: "Four of Cups",
        image: Cups04,
        upward: "The Four of Cups reminds the querent of times when it's necessary to reevaluate the current circumstances and introspect within.",
        downward: "Downward, the Four of Cups indicates that the querent is withdrawing and instead should be engaged with society."
    },
    {
        title: "Five of Cups",
        image: Cups05,
        upward: "The Five of Cups is a reminder that even in the event of loss and regret, there are still other options available waiting for you, they just need to be found.",
        downward: "Downward, the Five of Cups is a sign that loss and regret are greatly affecting the querent, and making amends with the past is recommended."
    },
    {
        title: "Six of Cups",
        image: Cups06,
        upward: "The Six of Cups is a card that reminds the querent of past times that brings nostalgia. Feel free to revisit those memories and take the best out of them.",
        downward: "Downward, the Six of Cups wants to inform the querent that while feeling a bit of nostalgia is fine, getting overwhelmed and affected is not. Avoid clinging to the past and be ready to move on."
    },
    {
        title: "Seven of Cups",
        image: Cups07,
        upward: "The Seven of Cups depicts wishful thinking and having many options but consider them carefully as they may not appear what they are at first.",
        downward: "Downward, the Seven of Cups tells that the correct decision has been made even amongst all the options that were available."
    },
    {
        title: "Eight of Cups",
        image: Cups08,
        upward: "The Eight of Cups brings to attention that the querent has put a good effort on a certain aspect of his life but resulted to be unfulfilling thus leaving the project behind.",
        downward: "Downward, the Eight of Cups reminds the querent of drifting aimlessly. It may be time to find a place to stay for a while."
    },
    {
        title: "Nine of Cups",
        image: Cups09,
        upward: "The Nine of Cups is an indication that your wish has come true and you have material success. But is there more?",
        downward: "Downward, your wish may have come true but still you find it unfulfilling or the wish has not come true yet."
    },
    {
        title: "Ten of Cups",
        image: Cups10,
        upward: "The Ten of Cups is an indication that the querent has very strong and happy relationships. Celebrate and enjoy what you have worked so hard for.",
        downward: "Downward, the Ten of Cups indicates that there are disagreements in the relationships of the querent making it a bit difficult to handle, but it doesn't mean it will always be like this."
    },
    {
        title: "Page of Cups",
        image: Cups11,
        upward: "The Page of Cups invites you to be open to new opportunities and embrace creativity.",
        downward: "Downward, the Page of Cups means that the person is emotionally immature. The person also suffers from unrealistic expectations."
    },
    {
        title: "Knight of Cups",
        image: Cups12,
        upward: "The Knight of Cups of a very creative, intelligent, charismatic, emotional person with a humanitarian mission.",
        downward: "Downward, the Knight of Cups might be overreactive or out of touch with reality. Might be prone to acts of jealousy."
    },
    {
        title: "Queen of Cups",
        image: Cups13,
        upward: "The Queen of Cups is someone who is in touch with their intuition, very caring, emotionally stable, and considered to be very special.",
        downward: "Downward, the Queen of Cups indicates there is an energy of manipulation on the air and you should be careful of others agendas."
    },
    {
        title: "King of Cups",
        image: Cups14,
        upward: "The King of Cups is a very stable person emotionally that can handle a lot of different situations without being emotionally drained.",
        downward: "Downward, the King of Cups wants to advise the querent to practice self-control and not let events cause an emotional trigger."
    },
    {
        title: "Ace of Swords",
        image: Swords01,
        upward: "The Ace of Swords is a sign that a new intellectual endeavor is emerging and is at the beginning stages. It is a positive message of great deeds to come.",
        downward: "Downward, the Ace of Swords lets us know that a new intellectual endeavor will require the querent to be very careful with his choices."
    },
    {
        title: "Two of Swords",
        image: Swords02,
        upward: "The Two of Swords is a sign of difficult decisions and the querent must weigh based on logic and reason. Find all the evidence and based on that make your decision.",
        downward: "Downward, the Two of Sword is a sign of difficult decisions and careful decision-making must be followed with risk management as the decision may not turn out as expected."
    },
    {
        title: "Three of Swords",
        image: Swords03,
        upward: "The Three of Swords is a sign of emotional hurt and resentment. In some cases triggers happen and they may be a source of emotional pain.",
        downward: "Downward, the Three of Swords is a reminder to make amends with the past even if it hurts. This will allow the querent to move on to the next phase."
    },
    {
        title: "Four of Swords",
        image: Swords04,
        upward: "The Four of Swords is an omen that the querent needs to rest. Take some time to recover from stress and prepare for what is to come.",
        downward: "Downward, the Four of Swords reminds the querent of situations that cause burnout and complete stress. It's important to consider taking a break since it's taking a toll on the health due to stress."
    },
    {
        title: "Five of Swords",
        image: Swords05,
        upward: "The Five of Swords appears in times when the querent had a disagreement or argument but ended up losing more even if it seems to have the advantage.",
        downward: "Downward, the Five of Swords indicates loss of a disagreement and taken advantage of, even if the querent was correct."
    },
    {
        title: "Six of Swords",
        image: Swords06,
        upward: "The Six of Swords appears when it is time to move on in hope for the better. Note that there are still burdens which are carried by the querent which must be resolved to continue.",
        downward: "Downward, the Six of Swords is an indication of stagnation at the current stage in life and not being able to move on. You'll need to use everything at your disposition to move on."
    },
    {
        title: "Seven of Swords",
        image: Swords07,
        upward: "The Seven of Swords reminds the querent that there is deception and betrayal in the current situation at hand.",
        downward: "Downward, the Seven of Swords indicates that the betrayer will fail at his attempt of causing harm and will be caught."
    },
    {
        title: "Eight of Swords",
        image: Swords08,
        upward: "The Eight of Swords reminds the querent that even if there is a sense of being trapped, reconsidering all the options might allow the use of an alternative.",
        downward: "Downward, the Eight of Swords indicates that a difficult situation has been solved and there is no longer a feeling of being held down."
    },
    {
        title: "Nine of Swords",
        image: Swords09,
        upward: "The Nine of Swords indicates the querent that excessive worry and guilt haunt daily leaving you powerless and very indecisive.",
        downward: "Downward, the Nine of Swords indicates the querent that there is healing undergoing. The darkest night has passed and you can see the light again."
    },
    {
        title: "Ten of Swords",
        image: Swords10,
        upward: "The Ten of Swords indicates that there has been treason and betrayal against the querent and the wounds are deep. The querent is someone that is making an impact on society.",
        downward: "Downward, the Ten of Swords is a sign that the querent has been deeply hurt and betrayed. The querent should move on forward alone without hesitation."
    },
    {
        title: "Page of Swords",
        image: Swords11,
        upward: "The Page of Swords is a person who is intellectual and diplomatic yet may act swiftly and has a go-for-it attitude.",
        downward: "Downward, the Page of Swords may be prone to mistakes due to acting swiftly and without careful consideration."
    },
    {
        title: "Knight of Swords",
        image: Swords12,
        upward: "The Knight of Swords indicates a person that is dominant, action-oriented, fast thinker. Set to accomplish his goals, is what matters the most.",
        downward: "Downward, the Knight of Swords indicates the querent should take a careful approach to the situation as it is delicate and rushing would only cause harm."
    },
    {
        title: "Queen of Swords",
        image: Swords13,
        upward: "The Queen of Swords is an intelligent, fast-thinking, deterministic, secure, and independent woman. She is strong because that is how life taught her to be.",
        downward: "Downward, the Queen of Swords represents someone who is being spiteful, resentful, cruel, and apathetic. The emotions generally cloud the Queen's mind at times when she is hot-headed."
    },
    {
        title: "King of Swords",
        image: Swords14,
        upward: "The King of Swords is a person who is very analytical and intellectual. Very firm on his way of being and expects the same from the others.",
        downward: "Downward, the King of Swords may not use his power in the most positive ways. By using hurtful words, being cynical, or giving strong criticism."
    },
    {
        title: "Ace of Pentacles",
        image: Pents01,
        upward: "Ace of Pentacles wants to inform you that an opportunity for financial gain is possible for you. That you have everything it takes to overcome the challenges this opportunity may bring.",
        downward: "Downward, the Ace of Pentacles reminds you to reassess the opportunity that may come to you related to financial gains and consider every aspect before compromising completely."
    },
    {
        title: "Two of Pentacles",
        image: Pents02,
        upward: "The Two of Pentacles reminds the querent is multitasking situations properly. Correctly balancing different aspects of life.",
        downward: "Downward, the Two of Pentacles means that there is currently too much going on and that it's starting to get difficult to keep up with the tasks. Careful planning should be done to meet every demand."
    },
    {
        title: "Three of Pentacles",
        image: Pents03,
        upward: "The Three of Pentacles appears on readings where there are projects that involve multiple parties. There will be recognition for good work.",
        downward: "Downward, the Three of Pentacles indicates that there is a lack of success and the querent is not seeing the expected results from his work."
    },
    {
        title: "Four of Pentacles",
        image: Pents04,
        upward: "The Four of Pentacles is a sign of times when material was given more importance over non-material. The influence of the card is of someone who saves and is a miser.",
        downward: "Downward, the Four of Pentacles indicates that there will be difficulties related to financial concerns and that you should prepare for any unexpected event."
    },
    {
        title: "Five of Pentacles",
        image: Pents05,
        upward: "The Five of Pentacles is a sign of poverty and lacking wealth. Do consider that there is help available and people to support the querent as long as the highest good is done.",
        downward: "Downward, the Five of Pentacles shows the querent that healing and recuperation are undergoing their way. The querent should continue to do the highest good in return."
    },
    {
        title: "Six of Pentacles",
        image: Pents06,
        upward: "The Six of Pentacles is a sign of social justice where alms are given to the ones who need it the most. A historic practice.",
        downward: "Downward, the Six of Pentacles indicates that you are a benefactor and rely on the generosity of others. I would be advisable to become more self-reliant."
    },
    {
        title: "Seven of Pentacles",
        image: Pents07,
        upward: "The Seven of Pentacles highlights that hard work, dedication, and patience have now spawned the fruits of labor and that the querent should reap its rewards.",
        downward: "Downward, the Seven of Pentacles highlights that hard work, dedication, and patience are not bearing its fruits as expected and this is taking its toll."
    },
    {
        title: "Eight of Pentacles",
        image: Pents08,
        upward: "The Eight of Pentacles is a confirmation of hard work, learning, and attention to detail has been invested in the current situation.",
        downward: "Downward, the Eight of Pentacles means not reaping rewards from your work but could if more hard work is done."
    },
    {
        title: "Nine of Pentacles",
        image: Pents09,
        upward: "The Nine of Pentacles pertains to financial success and independence. The querent is free to enjoy luxuries, time off, and other available benefits.",
        downward: "Downward, the Nine of Pentacles indicates that the querent is not satisfied with the status quo even after a lot has been achieved at this stage."
    },
    {
        title: "Ten of Pentacles",
        image: Pents10,
        upward: "The Ten of Pentacles wants to bring to your attention that you have achieved the pinnacle of success. With your hard work, you've obtained wealth and family members.",
        downward: "Downward, the Ten of Pentacles wants to let you know that there are financial and family challenges lurking around."
    },
    {
        title: "Page of Pentacles",
        image: Pents11,
        upward: "The Page of Pentacles is someone very enthusiastic about scholarship, business, wealth, and material possessions. Access your dreams and make them come true with all the possibilities open to you.",
        downward: "Downward, the Page of Pentacles may indicate someone who is extremely obsessed with material wealth until it is detrimental."
    },
    {
        title: "Knight of Pentacles",
        image: Pents12,
        upward: "The Knight of Pentacles reminds the querent of hard work, keen eye to detail, conservatism, and carefulness are qualities to be embraced. Take your time to tread a path along and follow it to accomplish your goals.",
        downward: "Downward, the Knight of Pentacles indicates lack of progress in work or feeling bored at the daily grind. Reassess the situation in careful detail for the next steps."
    },
    {
        title: "Queen of Pentacles",
        image: Pents13,
        upward: "The Queen of Pentacles is a sign of possessing beauty, wealth, being compassionate, independent and intelligent. The Queen is a very considerate and balanced person.",
        downward: "Downward, the Queen of Pentacles needs to strive for self-reliance to be a provider for her loved ones. Some challenges need to be revised."
    },
    {
        title: "King of Pentacles",
        image: Pents14,
        upward: "The King of Pentacles is smart and influential. A person who is generally involved in leadership and financial roles. Very reliable provider and caregiver for his loved ones.",
        downward: "Downward, the King of Pentacles is someone who is not respectful about money and may be involved on the dark side of money in certain aspects."
    },
]

export default deck;