import React from "react";

function HelpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
      clipRule="evenodd"
      viewBox="0 0 32 32"
    >
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="4.34"
        d="M13.532 9.85s4.758-4.07 6.718-3.495c1.959.575 4.062 4.047 3.631 6.036-.431 1.99-5.175 3.791-6.219 5.901-.999 2.021-.408 4.312-.408 4.312"
        transform="translate(-2.735 -2.451)"
      ></path>
      <ellipse
        cx="5.135"
        cy="24.216"
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.35"
        rx="1.362"
        ry="1.611"
        transform="translate(7.532 -11.766) scale(1.55399)"
      ></ellipse>
    </svg>
  );
}

export default HelpIcon;
