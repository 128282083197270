import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import Modal from './Modal'
import Button from './Button'
import SVGLogo from './Logo'

function Home() {
    const [reveal, setReveal] = useState(false)
    
    const revealModal = () => {
        setReveal(true)
    }
    const hideModal = () => {
        setReveal(false)
    }

    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement('script');
        const script2 = document.createElement('script');
      
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7881143807123640";
        script.async = true;
        script.crossOrigin = "anonymous"

        script2.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        script2.async = true;
        script2.crossOrigin = "anonymous"
      
        head.appendChild(script);
        head.appendChild(script2);
      
        return () => {
          head.removeChild(script);
          head.removeChild(script2);
        }
      }, []);

    return (
        <>
            <div className="home__main-app">
                <SVGLogo />
                <h1>Tarot Realms of Insight</h1>
                <p className="home__margin-20">This is a website where you can play different kinds of tarot spreads known for free.</p>
                <p className="home__margin-20"><b>Disclaimer:</b> The content provided on this website is for entertainment purposes only and is not intended to be a substitute for professional advice. If you need assistance with serious matters, please consult a qualified professional in the relevant field.</p>
                <p className="home__margin-20">Following are the spreads available:</p>
                <ul>
                    <li>Draw one card</li>
                    <li>Three Card Spread</li>
                    <li>Celtic Cross</li>
                </ul>
                <div className="home__margin-20 home__play-button" onClick={revealModal}>
                    <Button>Play</Button>
                </div>
                <h2 className="home__margin-20">To see the meanings of the cards:</h2>
                <div className="home__margin-20">
                    <Link to="/meanings" className="home__modal-links meaninigs__link"><Button>View Meanings</Button></Link>
                </div>
                <Link to="/privacypolicy" className="home__modal-links meaninigs__link"><Button>View Privacy Policy</Button></Link>
                <Modal reveal={reveal} timeout={0}>
                    <p className="modal-exit" onClick={hideModal}>X</p>
                    <h3 className="home__modal-title">Pick a Spread</h3>
                    <div className="home__modal-spreads">
                        <Link to={`/board`} className="home__modal-links" state={{ spread: 'drawOne' }} aria-label="Play the Draw One Card">One Card</Link>
                        <Link to={`/board`} className="home__modal-links" state={{ spread: 'threeCards' }} aria-label="Play the Three Card Spread">Three Cards</Link>
                        <Link to={`/board`} className="home__modal-links" state={{ spread: 'celticCross' }} aria-label="Play the Celtic Cross Spread">Celtic Cross</Link>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default Home;
